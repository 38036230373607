@tailwind base;

body {
  margin: 0 !important;
  padding: 0 !important;
}
.container {
  @apply px-5 lg:px-10;
}

/* tooltip */
.tooltip {
  position: relative;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.tooltip .tooltip-content {
  background-color: #000000;
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
  font-weight: normal;
  padding: 4px;
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  text-align: center;
  line-height: 1.4;
  z-index: 99;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.tooltip .tooltip-content:after {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
}

.tooltip .tooltip-top {
  bottom: 100%;
  margin-bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip .tooltip-top:after {
  border-left: solid transparent 6px;
  border-right: solid transparent 6px;
  border-top: solid #000000 6px;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip .tooltip-bottom {
  top: 100%;
  margin-top: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip .tooltip-bottom:after {
  border-left: solid transparent 6px;
  border-right: solid transparent 6px;
  border-bottom: solid rgba(0, 0, 0, 0.9) 6px;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip .tooltip-left {
  right: 100%;
  margin-right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip .tooltip-left:after {
  border-top: solid transparent 6px;
  border-bottom: solid transparent 6px;
  border-left: solid rgba(0, 0, 0, 0.9) 6px;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip .tooltip-right {
  left: 100%;
  margin-left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip .tooltip-right:after {
  border-top: solid transparent 6px;
  border-bottom: solid transparent 6px;
  border-right: solid rgba(0, 0, 0, 0.9) 6px;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip:hover .tooltip-content {
  opacity: 1;
  pointer-events: auto;
}

/* Social Login */
.kep-login-facebook.metro {
  width: 100% !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  cursor: pointer !important;
  text-transform: none !important;
  color: #3c4043 !important;
  font-weight: 500 !important;
  border: 1px solid #71717a !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  height: 40px;
}
.kep-login-facebook.metro .fa-facebook-square {
  color: #3b5998 !important;
  font-size: 16px !important;
}

::selection {
  @apply bg-primary text-white;
}

@media screen and (max-width: 1024px) {
  ::-webkit-scrollbar {
    display: none;
  }
  :not(input):not(textarea):not(button)  {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
}

.text404 {
  background: linear-gradient(
    to right top,
    #fffe00,
    #ffe600,
    #ffce00,
    #ffb700,
    #ffa00b,
    #fd9611,
    #fa8d16,
    #f7831b,
    #f98316,
    #fb8411,
    #fc840a,
    #fe8501
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.loader {
  width: 25px;
  height: 25px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.disable-scroll {
  overflow: hidden;
}

.slick-track {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ckEditor a {
  color: #0291dd !important;
}
.ckEditor li {
  list-style-position: outside !important;
  font-size: 14px !important;
  margin-bottom: 6px;
  padding-left: 10px;
}
.ckEditor ol {
  list-style-type: decimal !important;
  margin-bottom: 20px;
  margin-left: 40px;
}
.ckEditor ul {
  list-style-type: disc !important;
  margin-bottom: 20px;
  margin-left: 0;
}
.ckEditor .marker {
  background: yellow !important;
}
.ckEditor blockquote {
  border-left: 4px solid #0291dd;
  padding: 0 20px;
  margin: 20x;
}
.ckEditor blockquote p {
  font-size: 24px !important;
}
.ckEditor ol p,
.ckEditor ul p {
  display: inline !important;
}
.ckEditor table,
.ckEditor td {
  border: 1px solid #ccc;
  padding: 4px;
  background-color: #fff;
}
.ckEditor p {
  margin-top: 10px;
}
.imported-content table {
  border: none;
}
.imported-content p {
  margin-top: 20px;
}
.imported-content {
  color: #999;
  line-height: 1.5;
}

.imported-content h1 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
}
.imported-content h2,
.imported-content h3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 40px;
}

.imported-content .table-responsive table tbody td {
  border: 1px solid #dee2e6;
}
.imported-content table.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.imported-content table.table-striped thead th {
  background: none !important;
  padding: 10px;
}
.imported-content table.table-striped thead span {
  text-decoration: none !important;
}
.imported-content table.table-striped thead th strong {
  font-size: 20px;
}

.imported-content td {
  padding: 10px 5px;
}
.imported-content img {
  margin: 20px auto;
}
img.size-large {
  float: left;
}
ul.list-bullet {
  list-style-type: disc;
  list-style-position: inside;
}

.gray_tint_bg {
  background-image: linear-gradient(
    to right bottom,
    #e8e8e8,
    #fefefe,
    #ffffff,
    #ffffff,
    #ffffff
  );
}
.mantine-active.mantine-Pagination-control[data-active="true"] {
  color: white !important;
  background-color: #0030b7 !important;
}

img.alignleft {
  float: left;
  margin-right: 20px;
}
img.alignright {
  float: right;
  margin-left: 20px;
}
.imported-content hr {
  margin: 20px 0;
}

@tailwind components;

@tailwind utilities;
